<template>
  <layout>
    <div class="row mb-3 align-items-center">
      <div class="col">
        <PageHeader :title="title" />
      </div>
      <div class="col-auto">
        <router-link to="/add-user" class="btn btn-theme">
          <i class="bx bx-user-plus font-size-18 align-middle me-2"></i>
          Add User
        </router-link>
      </div>
    </div>
      <div class="card">
        <div class="card-body">
          <div class="main-search-card ">
            <div class="topBarCstmTable mb-3">
              <div class="row gx-2">
                <div class="col">
                  <div class="searchBarStyle position-relative">
                    <input type="text" v-model="searchValueTable" class="form-control" placeholder="Search...">
                    <span class="iconSearch bx bx-search-alt"></span>
                  </div>
                </div>
                <div class="col-auto">
                  <button @click="isshow = !isshow" class="btn btn-theme">Advance Search</button>
                </div>
              </div>
            </div>
            <Transition name="fade">
              <div class="main-group-search p-3 border mb-3" v-if="isshow">
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label class="form-label">User Name</label>
                    <div class="single-select2-cstm">
                      <Select2 v-model="userName" placeholder="Select user Name" :options="userNameOptions"
                        :settings="{ settingOption: value, settingOption: value }" @change="myChangeEvent($event)"
                        @select="mySelectEvent('name')" />
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label class="form-label">User Email</label>
                    <div class="single-select2-cstm">
                      <Select2 v-model="userEmail" placeholder="Select user Email" :options="userEmailOptions"
                        :settings="{ settingOption: value, settingOption: value }" @change="myChangeEvent($event)"
                        @select="mySelectEvent('email')" />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="row mt-3 gx-2 justify-content-end">
                      <div class="col-auto">
                        <button class="btn btn-light" @click="getUsers()">Reset</button>
                      </div>
                      <div class="col-auto">
                        <button class="btn btn-theme" @click="filterData()">Apply</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition>

            <EasyDataTable :headers="tableHeaders" :items="tableItems" :search-value="searchValueTable" border-cell
              theme-color="#3aafa9" :rows-per-page="25" buttons-pagination table-class-name="table-custom-style">
              <template #loading>
                <img
                  src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
                  alt="loading"
                  style="width: 100px; height: 80px;"
                />
              </template>
              <template #item-fullName="{ firstName, lastName, id }">
                <router-link :to="`/single-user/${encode(id)}`" class="text-theme"> {{ firstName }}
                  {{ lastName }}</router-link>
              </template>
              <template #item-status="{ status }">
                <span :class="{
                  'text-success': `${status}` === '1',
                  'text-danger': `${status}` === '0',
                  'text-warning': `${status}` === '-1',
                }">
                  <p v-if="status == '1'">Active</p>
                  <p v-else-if="status == '0'">Retired</p>
                  <p v-else>Signed Up</p>
                </span>
              </template>
              <template #item-action="{ id }">
                <button @click="deleteUser(id)" class="btn btn-sm btn-soft-danger ms-1">
                  <i class="mdi mdi-trash-can-outline"></i>
                </button>
              </template>
            </EasyDataTable>
          </div>
        </div>
      </div>
    </layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/admin/header/page-header";
import EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import Select2 from 'vue3-select2-component';
import axios from 'axios';
import CryptoJS from 'crypto-js';

export default {
  page: {
    title: "Users",
    meta: [
      {
        name: "users",
        content: 'users',
      },
    ],
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    size: {
      type: String,
      default: '20'
    },
    characters: {
      type: String,
      default: 'a-z,A-Z,0-9,#'
    },
    auto: [String, Boolean],
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      url: process.env.VUE_APP_URL,
      dataFetched: false,
      isshow: false,
      isShowData: false,
      title: "Users",
      autoClose: new Date(),
      searchDateClose: new Date(),
      userNameOptions: [],
      userEmailOptions: [],
      isOpen: false,
      headers: [
        { text: "Name", value: "fullName" },
        { text: "Email", value: "email" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" },
      ],
      tableItems: [],
      tableData: [],
      searchValueTable: "",
    }
  },
  components: {
    Layout,
    PageHeader,
    EasyDataTable,
    Select2
  },
  beforeMount() {
    if (!this.dataFetched) {
      this.getUsers();
    }
  },
  activated() {
    if (this.dataFetched) {
      this.getUsers()
    }
  },
  computed: {
    tableHeaders() {
      return this.headers.filter((header) => header.value !== "action" || this.$store.state?.authenticatedUser?.can_delete_users);
    }
  },
  methods: {
    mySelectEvent(eventType) {
      if (eventType == 'email') {
        this.userNameOptions = this.tableData.filter(user => user.email == this.userEmail).map(({ firstName, lastName }) => firstName + ' ' + lastName)
      }
      else if (eventType == 'name') {
        this.userEmailOptions = this.tableData.filter(user => user.firstName == this.userName.split(' ')[0]).map(({ email }) => email)
      }
      else {
        this.userNameOptions = this.tableData.map(({ firstName, lastName }) => firstName + ' ' + lastName)
        this.userEmailOptions = this.tableData.map(({ email }) => email)
      }
    },
    getUsers() {
      this.userName = "";
      this.userEmail = "";
      axios.get(this.url + 'api/admins').then(res => {
        this.tableItems = res.data;
        this.tableData = res.data;
        this.userNameOptions = this.tableItems.map(({ firstName, lastName }) => firstName + ' ' + lastName);
        this.userEmailOptions = this.tableItems.map(({ email }) => email);
        this.dataFetched = true
      })
    },
    deleteUser(id) {
      if (confirm('Are you sure?')) {
        axios.delete(this.url + `api/admins/${id}`, {
          headers: {
            'X-User-Id': atob(decodeURIComponent(JSON.parse(localStorage.getItem('authenticatedUserId'))))
          }
        })
          .then(() => {
            this.tableData = this.tableData.filter((item) => item.id != id);
            this.tableItems = this.tableData;
            this.$store.state.isAccepted = true
            this.$store.state.notificationData = 'User Deleted Successfully'
            setTimeout(() => {
              this.$store.state.isAccepted = false
              this.$store.state.notificationData = null
            }, 2500)
            this.getUsers()
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    filterData() {
      if (this.userEmail) {
        this.tableItems = this.tableData.filter(user => user.email == this.userEmail)
      }
      else if (this.userName) {
        this.tableItems = this.tableData.filter(user => user.firstName == this.userName.split(' ')[0])
      }
    },
    encode(id) {
      return encodeURIComponent(CryptoJS.AES.encrypt(String(id), "Secret Passphrase"));
    }
  }
};
</script>

<style scoped>
.fade-enter-active {
  transition: all 0.3s ease-out;
}
.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter-from,
.fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>